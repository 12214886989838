import * as React from 'react'
import LinksGrid from '../LinksGrid'
import { LinksGrid as LinksGridClass } from '../LinksGrid/model'
import './style.scss'

const Footer: React.FC<LinksGridClass> = ({
  linksColumns,
}) => {

  return (
    <section className="Footer mt-mobile-xl lg:mt-xl">
      <div className="overflow-auto">
        <div className="bg-gray-5 box-content w-mobile-foot-grid lg:w-auto px-m pb-mobile-l lg:pb-xl overflow-hidden pt-mobile-m lg:pt-m">
          <LinksGrid
            linksColumns={linksColumns}
            textColor="text-black"
            borderColor="border-separator border-opacity-separator"
            linkHoverColor="text-black"
            animate
          />
        </div>
      </div>
    </section>
  )
}

export default Footer
