import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import { AnimationService } from '../../common/AnimationService'
import './style.scss'

interface MarqueeProps {
  textLine: string
  speed: number
  addGap?: boolean
}

const Marquee: React.FC<MarqueeProps> = ({ textLine, speed, addGap = false }) => {
  const isReversed = speed < 0
  const orgBoxRef = React.useRef<HTMLDivElement>(null)
  const shadowBoxRef = React.useRef<HTMLDivElement>(null)
  const [gap, setGap] = React.useState<number>(0)

  React.useEffect(()=>{
    let loop: GSAPTimeline
    const orgBox = orgBoxRef.current as HTMLElement
    const shadowBox = shadowBoxRef.current as HTMLElement
    
    const buildAnimation = () => {
      const progress = loop ? loop.progress() : 0
      loop && loop.progress(0).kill()
      if (speed === 0) {
        return
      }
      loop = AnimationService.horizontalLoop(
        orgBox, 
        shadowBox, 
        {
          paused: false,
          repeat: -1,
          speed: Math.abs(speed),
        }, 
        isReversed
      )
      loop.progress(progress)
    }

    const resizeObserver = new ResizeObserver(() => {
      if (orgBoxRef.current) {
        const fontSize = parseFloat(getComputedStyle(orgBoxRef.current as HTMLDivElement).fontSize)
        addGap && setGap(Math.ceil(fontSize))
        buildAnimation()
      }
    })
    resizeObserver.observe(orgBox) // to re-calculate timeline after font loading

    return () => {
      loop && loop.progress(0).kill()
      resizeObserver.disconnect()
    }
  } , [isReversed, speed, addGap])

  return (
    <div className={`Marquee flex ${isReversed ? 'flex-row-reverse' : 'flex-row'} justify-start items-center overflow-hidden`}>
      <div
        ref={orgBoxRef} 
        className="flex justify-center items-center flex-shrink-0 whitespace-nowrap min-w-full"
        style={{marginLeft: isReversed ? `${gap}px` : 0, marginRight: isReversed ? 0 : `${gap}px`}}
      >
        <RichTextElement value={textLine} />
      </div>
      <div 
        ref={shadowBoxRef}
        className="flex justify-center items-center flex-shrink-0 whitespace-nowrap min-w-full"
        style={{marginLeft: isReversed ? `${gap}px` : 0, marginRight: isReversed ? 0 : `${gap}px`}}
      >
        <RichTextElement value={textLine} />
      </div>
    </div>
  )
}

export default Marquee
