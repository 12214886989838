import { graphql } from 'gatsby'
import _get from 'lodash/get'

/* Kentico model: Blog Article - Content */
interface KenticoBlogArticleContent extends KontentItem {
  value: KontentRichTextElement
  images: {
    url: KontentTextElement
    image_id: KontentTextElement
    height: KontentNumberElement
    width: KontentNumberElement
    description: KontentTextElement
  }[]
  links: {
    url: KontentTextElement
    link_id: KontentTextElement
  }
  modular_content: KontentElement
}

type Image = {
  url: string
  image_id: string
  height: number
  width: number
  name: string
  description: string
}

export class ArticleContent {
  value: string
  images: Image[]
  links: {
    url: string
    link_id: string
  }[]
  modular_content: {
    system: {
      codename: string
      type: string
    }
    image_first_column?: Image
    image_second_column?: Image
    video_url?: string
  }[]

  constructor(data: KenticoBlogArticleContent) {
    this.value = _get(data, 'content.value', '')
    this.images = _get(data, 'content.images', [])
    this.links = _get(data, 'content.links', '')
    this.modular_content = _get(data, 'content.modular_content', []).map((component: KontentElement) => {
      const componentType = _get(component, 'system.type', '')
      switch (componentType) {
      case 'two_column_images': return {
        system: _get(component, 'system', {}),
        image_first_column: _get(component, 'elements.first_column.value[0]', {}),
        image_second_column: _get(component, 'elements.second_column.value[0]', {})  
      }
      case 'video_in_article': return {
        system: _get(component, 'system', {}),
        video_url: _get(component, 'elements.video_in_article.value[0].url', '')
      }
      default: return component
      }
    })
  }
}

export const richTextContentFragment = graphql`
  fragment kontent_item_rich_text_element_valueFragment on kontent_item_rich_text_element_value {
    value
    images {
      url
      image_id
      height
      width
      description
    }
    links {
      url_slug
      link_id
    }
    modular_content {
      ... on kontent_item_two_column_images {
        elements {
          first_column {
            value {
              url
              width
              height
              name
              description
            }
          }
          second_column {
            value {
              url
              width
              height
              name
              description
            }
          }
        }
        system {
          codename
          type
        }
      }
      ... on kontent_item_video_in_article {
        id
        elements {
          video_in_article {
            value {
              url
            }
          }
        }
        system {
          codename
          type
        }
      }
    }
  }
`

export const useArticleContentData = (data: KenticoBlogArticleContent) => {
  return new ArticleContent(data)
}