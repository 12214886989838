import * as React from 'react'
import { ClientsSection as ClientsSectionClass } from './model'
import Marquee from '../Marquee'
import { AnimationService } from '../../common/AnimationService'
import './style.scss'

const ClientsSection: React.FC<ClientsSectionClass> = ({ header, richText, scrollingSpeed }) => {
  const headerRef = React.useRef<HTMLHeadingElement>(null)
  React.useEffect(() => {
    AnimationService.slideUp(headerRef.current)
  }, [])

  return (
    <>
      <section className="ClientsSection GlobSectPadSpacing mx-0">
        <div className="mx-mobile-xs lg:mx-m mb-mobile-m lg:mb-m border-t border-separator border-opacity-separator"></div>
        <h2 className='FontL block mb-mobile-s mx-mobile-xs lg:mx-m lg:mb-s' ref={headerRef}>{header}</h2>
        <div className="FontXL">
          <Marquee textLine={richText} speed={scrollingSpeed} />
        </div>
      </section>
    </>
  )
}

export default ClientsSection
