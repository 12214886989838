import * as React from 'react'
import PlusButton from '../PlusButton'
import { Link } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { localizeDate } from '../../common/serviceHelpers'
import { LanguageContext } from '../../common/Context'
import { DynamicSitesCategory } from '../../common/typings/enums'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality, ImageAspectRatio } from '../../common/typings/enums'
import ParallaxImage from '../ParallaxImage'
import './style.scss'

export interface ImageWithTextProps {
  image: ImageItem,
  videoUrl: string,
  parallax: ImageItem[],
  title: string,
  description?: string,
  date?: Date
  target: string
  style?: string
  category?: DynamicSitesCategory | string
  grids?: string
  buttonText?: string
  keepAspectRatio?: boolean
  animate?: boolean
}

const ImageWithText = React.forwardRef<HTMLDivElement, ImageWithTextProps>((props: ImageWithTextProps, ref) => {
  const {
    image,
    videoUrl,
    parallax,
    title,
    description,
    date,
    target,
    style,
    category,
    grids,
    buttonText,
    keepAspectRatio = false,
    animate = true
  } = props
  const { language } = React.useContext(LanguageContext)
  const parsedDate = localizeDate(date, language)
  const [hovered, setHovered] = React.useState(false)
  const { t } = useTranslation()

  const imgRef = React.useRef<HTMLDivElement>(null)
  const titleRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (animate) {
      if (!AnimationService.isInView(imgRef.current)) {
        AnimationService.slideUp(imgRef.current)
      }
      if (!AnimationService.isInView(titleRef.current)) {
        AnimationService.slideUp(titleRef.current)
      }
      if (!AnimationService.isInView(buttonRef.current)) {
        AnimationService.slideUp(buttonRef.current)
      }
    }
  }, [animate])

  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const imageStyle = React.useMemo(() => {
    return keepAspectRatio
      ? undefined
      : { aspectRatio: ImageAspectRatio.RECTANGLE, height: '100%' }
  }, [keepAspectRatio])

  const defaultButtonText = category === DynamicSitesCategory.WORK ? t('common:caseStudy') : t('common:read')
  const imageWithTextInside = (target: string) => {
    return (
      <>
        <div className="ImageVideoWrapper relative overflow-hidden h-auto flex items-center justify-center col-span-full" ref={imgRef}>
          {image && (
            <ParallaxImage
              backgroundImage={image}
              layer1Image={parallax[0]}
              layer2Image={parallax[1]}
              layout="fullWidth"
              alt={image.description || title}
              className={`${target && 'transition ease-in-out duration-500 transform group-hover:scale-110'} object-cover w-full ${style}`}
              style={imageStyle}
              options={imageQualityOptions}
            />
          )}
          {videoUrl && (
            <video
              autoPlay muted loop playsInline
              className={`Video ${target && 'transition ease-in-out duration-500 transform group-hover:scale-110'} object-cover w-full ${style}`}
              style={imageStyle}
            >
              <source
                src={videoUrl}
                type="video/mp4"
              />
            </video>
          )}
        </div>
        <div className='mx-mobile-xs lg:mx-0 relative lg:col-span-6'>
          <div className='Title' ref={titleRef}>
            <span className='FontM block mt-mobile-s lg:mt-s'>{title}</span>
            <span className='FontM text-gray-40 block mb-mobile-s lg:mb-s'>
              {description ? description : parsedDate}
            </span>
          </div>
          <div className='Button pointer-events-none' ref={buttonRef}>
            <PlusButton
              isHovered={hovered}
              text={buttonText || defaultButtonText}
              hoveredText={target ? t('common:read') : t('common:moreInfoSoon')}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div ref={ref} className={`ImageWithText group ${target ? 'cursor-pointer' : ''}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {target ?
        <Link to={`/${category}/${target}`} className={`${grids} overflow-visible`}>
          {imageWithTextInside(target)}
        </Link> :
        <div className={`${grids} overflow-visible`}>
          {imageWithTextInside(target)}
        </div>
      }
    </div>
  )
})
ImageWithText.displayName = 'ImageWithText'

export default ImageWithText
