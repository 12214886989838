import * as React from 'react'
import ArrowButton from '../ArrowButton'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useScroll } from '../../hooks/useScroll'
import { ArrowDirection, ArrowColor } from '../../common/typings/enums'
import './style.scss'

const FloatingButton: React.FC = () => {
  const { t } = useTranslation()
  const { isThresholdPassed } = useScroll({ threshold: 100 })
  const [started, setStarted] = React.useState(false)

  React.useEffect(() => {
    if (isThresholdPassed) {
      setStarted(true)
    }
  }, [isThresholdPassed])

  const buttonStyles = `FloatingButton m-s flex items-center FontM fixed 
    bottom-mobile-xs lg:bottom-xs right-mobile-s lg:right-s opacity-0 z-40 
    ${started ? 'visible' : 'hidden'} 
    ${isThresholdPassed ? 'animate-fadeIn' : 'animate-fadeOut'}`

  return (
    <ArrowButton
      title={t('common:toTop')}
      direction={ArrowDirection.UP}
      className={buttonStyles}
      scrollToId={'___gatsby'}
      colorSet={ArrowColor.BLACK}
    />
  )
}

export default FloatingButton
