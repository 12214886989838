import * as React from 'react'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { useTranslation } from 'react-i18next'
import { ArrowDirection } from '../../common/typings/enums'
import ArrowButton from '../ArrowButton'
import { ImageVideoSection as ImageVideoSectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality } from '../../common/typings/enums'
import './style.scss'

export interface ImageVideoProps extends ImageVideoSectionClass {
  className?: string
  arrowDirection?: ArrowDirection
}

const ImageVideoSection: React.FC<ImageVideoProps> = (imageVideoSectionData) => {

  const headingRef = React.useRef<HTMLHeadingElement>(null)
  const textRef = React.useRef<HTMLParagraphElement>(null)
  const arrowRef = React.useRef<HTMLDivElement>(null)
  const imageRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    AnimationService.fadeIn(imageRef.current)
    AnimationService.slideUp(headingRef.current)
    AnimationService.slideUp(textRef.current)
    AnimationService.slideUp(arrowRef.current)
  }, [])

  const {
    heading,
    description,
    image,
    videoUrl,
    backgroundColor,
    backgroundColorArea = 'content box',
    buttonText,
    buttonTargetUrl,
    className,
    arrowDirection = ArrowDirection.RIGHT,
    embeddedVideo
  } = imageVideoSectionData

  const { t } = useTranslation()
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const backgroundUnderSpacer = (backgroundColorArea === 'border box') || undefined
  const buttonTitle = buttonText || t('common:clickHere') as string

  return (<>
    <section
      className={`ImageVideoSection GlobSectPadSpacing ${className || ''}`}
      style={backgroundUnderSpacer && { backgroundColor: backgroundColor}}
    >
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div style={{ backgroundColor: backgroundColor }}>
        <div className="grid grid-cols-12 gap-x-m">
          <div className='TextWrapper col-span-12 lg:col-span-5 my-mobile-l lg:my-m lg:py-l pl-m pr-m lg:pr-0'>
            <h1 ref={headingRef} className='FontXL mb-mobile-s lg:mb-s'>{heading}</h1>
            <p ref={textRef} className='FontL mb-mobile-s lg:mb-s'>{description}</p>
            <div ref={arrowRef}>
              {buttonTargetUrl && <ArrowButton title={buttonTitle} url={buttonTargetUrl} direction={arrowDirection} />}
            </div>
          </div>
          <div ref={imageRef} className={`ImageVideoWrapper col-span-12 lg:col-span-7 overflow-hidden ${image ? '' : 'flex'} relative w-full`}>
            {image && (
              <ImageElement
                image={image}
                layout="fullWidth"
                alt={image.description || image.name}
                className="Image lg:h-full"
                options={imageQualityOptions}
              />
            )}
            {videoUrl && (
              <video
                title={heading}
                autoPlay muted loop playsInline
                className='Video overflow-hidden flex object-cover'>
                <source
                  src={videoUrl}
                  type="video/mp4"
                />
              </video>
            )}
            {embeddedVideo && (
              <iframe
                className='EmbeddedVideo w-full'
                src={embeddedVideo}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                title="youtube video"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
      {backgroundUnderSpacer && backgroundColor && <div className="pt-mobile-m lg:pt-m" style={{ backgroundColor: backgroundColor }}></div>}
    </section >
  </>)
}

export default ImageVideoSection
