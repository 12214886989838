import * as React from 'react'
import { localizeDate } from '../../common/serviceHelpers'
import { LanguageContext } from '../../common/Context'
import { AnimationService } from '../../common/AnimationService'
import Link from '../Link'
import { convertToSlug } from '../../common/serviceHelpers'
import './style.scss'

interface ArticleHeader {
  title: string
  leading: string
  date: Date
  author: string
  readTime: string
}

const ArticleHeader: React.FC<ArticleHeader> = ({ title, leading, date, author, readTime }) => {
  const { language } = React.useContext(LanguageContext)
  const parsedDate = localizeDate(date, language)
  const nameSlug = convertToSlug(author)

  const headerRef = React.useRef<HTMLDivElement>(null)
  const leadRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (!AnimationService.isInView(headerRef.current)) {
      const elementsToAnimate = [headerRef.current, leadRef.current]
      AnimationService.slideSequenceUp(elementsToAnimate)
    }
  }, [])

  return (
    <section className="ArticleHeader GlobSectPadSpacing mx-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className="grid grid-cols-12 gap-x-m">
        <div className='col-span-12 lg:col-span-6 overflow-hidden'>
          <h1 className='FontXL mb-mobile-s lg:mb-s' ref={headerRef}>{title}</h1>
        </div>
        <div className='col-span-12 lg:col-span-6 overflow-hidden'>
          <div className='Description' ref={leadRef}>
            <p className='FontL mb-mobile-s lg:mb-s'>{leading}</p>
            {nameSlug && <Link className='FontS mr-mobile-xs lg:mr-s' url={`/blog/author/${nameSlug}`}>{author}</Link>}
            <span className='FontS mr-mobile-xs lg:mr-s'>{parsedDate}</span>
            <span className='FontS'>{readTime}</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ArticleHeader
