import * as React from 'react'
import _get from 'lodash/get'
import { graphql, PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { readingTime } from '../common/serviceHelpers'
import { DynamicSitesCategory } from '../common/typings/enums'
import { AnimationService } from '../common/AnimationService'

// components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FloatingButton from '../components/FloatingButton'
import ArticleHeader from '../components/ArticleHeader'
import ArticleContent from '../components/ArticleContent'
import ArticleAuthorSection from '../components/ArticleAuthorSection'
import LastArticlesSection from '../components/LastArticlesSection'
import ClientsSection from '../components/ClientsSection'
import ImageVideoSection from '../components/ImageVideoSection'
import Breadcrumbs from '../components/Breadcrumbs'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'

// hooks, models
import { Employee } from '../components/Person/model'
import { SeoData } from '../components/SeoComponent/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useLastArticlesSectionData, LastArticlesSection as LastArticlesSectionClass } from '../components/LastArticlesSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useArticleContentData, ArticleContent as ArticleContentClass } from '../components/ArticleContent/model'

import { ImageQuality } from '../common/typings/enums'
import withSSR from '../hoc/withSSR'
import './blog-article.scss'

const ArticlePage: React.FC<PageProps> = (props) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const mainPath = 'data.allKontentItemBlogArticle.nodes[0].elements'
  const elements = _get(props, `${mainPath}`, {})
  const siteMeta = _get(props, 'data.site.siteMetadata', {})
  const pagePathname = _get(props, 'location.pathname', '')
  const seoData = new SeoData({ ...elements, siteMeta }, pagePathname)
  const linksGridData = useLinksGridData(language, 'blog_article_page')
  const navbarData = useNavbarData(language, 'blog_article_page', pagePathname)
  const articleContentData = useArticleContentData(_get(props, `${mainPath}`, ''))
  const articleHeaderData: ArticleHeader = {
    title: _get(props, `${mainPath}.title.value`, ''),
    leading: _get(props, `${mainPath}.lead.value`, ''),
    date: new Date(_get(props, `${mainPath}.publication_date.value`, '')),
    author: _get(props, `${mainPath}.article_author.value[0].elements.name.value`, ''),
    readTime: `${readingTime(articleContentData.value)} min`
  }
  const articleCategory = _get(props, `${mainPath}.blog_category.value[0].name`, '').replace(/\s+/g, '-').toLowerCase()
  const articleSlug = _get(props, `${mainPath}.slugs.value`, '')
  const leadingAssetType = _get(props, `${mainPath}.full_width_image_or_video.value[0].type`, '')
  const leadingImage = leadingAssetType.includes('image') ? _get(props, `${mainPath}.full_width_image_or_video.value[0]`) : ''
  const leadingVideoUrl = leadingAssetType.includes('video') ? _get(props, `${mainPath}.full_width_image_or_video.value[0].url`) : ''
  const articleAuthor = new Employee(_get(props, `${mainPath}.article_author.value[0]`, {}))
  const currentArticleId = _get(props, 'data.allKontentItemBlogArticle.nodes[0].id', '')
  const lastArticlesSectionData = useLastArticlesSectionData(language, currentArticleId)
  const clientsSectionData = useClientsSectionData(language, 'blog_article_page')
  const imageVideoSectionData = useImageVideoSectionData(language, 'blog_article_page', 'image_video_section')

  const pageData = {
    linksGridData,
    navbarData,
    articleHeaderData,
    articleCategory,
    articleSlug,
    leadingImage,
    leadingVideoUrl,
    articleContentData,
    articleAuthor,
    lastArticlesSectionData,
    clientsSectionData,
    imageVideoSectionData,
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <ArticlePageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface ArticlePageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    articleHeaderData: ArticleHeader
    articleCategory: string
    articleSlug: string
    leadingImage: ImageItem
    leadingVideoUrl: string
    articleContentData: ArticleContentClass
    articleAuthor: Employee
    lastArticlesSectionData: LastArticlesSectionClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
  }
}

const ArticlePageTemplate: React.FC<ArticlePageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    articleHeaderData,
    articleCategory,
    articleSlug,
    leadingImage,
    leadingVideoUrl,
    articleContentData,
    articleAuthor,
    lastArticlesSectionData,
    clientsSectionData,
    imageVideoSectionData,
  }
}) => {
  const { t } = useTranslation()
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])

  const imgRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (imgRef.current) {
      AnimationService.fadeIn(imgRef.current)
    }
  }, [])

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="ArticlePage">
        <ArticleHeader {...articleHeaderData} />
        {
          isMobile ||
          <Breadcrumbs
            siteCategory={DynamicSitesCategory.BLOG}
            title={articleHeaderData.title}
            category={articleCategory}
            slug={articleSlug}
          />
        }
        <section className="FullWidthImageOrVideo GlobSectPadSpacing h-auto mx-0 overflow-hidden flex items-center justify-center lg:mx-m">
          <div className='Image w-full h-full' ref={imgRef}>
            {leadingImage && (
              <ImageElement
                image={leadingImage}
                layout="fullWidth"
                alt={leadingImage.description || articleHeaderData.title}
                className="object-cover h-mobile-xxxxl lg:h-xxxxl w-full"
                options={imageQualityOptions}
              />
            )}
            {leadingVideoUrl && (
              <video autoPlay muted loop playsInline className="Video object-cover h-mobile-xxxxl lg:h-xxxxl w-full">
                <source
                  src={leadingVideoUrl}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </section>
        <section className="GlobSectPadSpacing mx-mobile-xs lg:mx-m">
          <ArticleContent {...articleContentData} />
        </section>
        <ArticleAuthorSection {...articleAuthor} />
        <LastArticlesSection {...lastArticlesSectionData} lead={t('common:readMoreArticles')} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const ArticlePageTemplateWithSSR = withSSR<ArticlePageProps>(ArticlePageTemplate)

export default ArticlePage

export const query = graphql`
  query($postId: String, $language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","blog"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allKontentItemBlogArticle(filter: {preferred_language: {eq: $language}, id: {eq: $postId}}) {
      nodes {
        id
        preferred_language
        elements {
          full_width_image_or_video {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
          title {
            value
          }
          publication_date {
            value
          }
          slugs {
            value
          }
          blog_category {
            value {
              name
            }
          }
          article_author {
            value {
              ... on kontent_item_employee_element {
                elements {
                  ...kontent_item_employee_element_elementsFragment
                }
              }
            }
          }
          content {
            ...kontent_item_rich_text_element_valueFragment
          }
          lead {
            value
          }
          listing_image_or_video {
            value {
              url
              description
            }
          }
          meta_tags__meta_description {
            value
          }
          meta_tags__meta_image {
            value {
              url
            }
          }
          meta_tags__meta_keywords {
            value
          }
          meta_tags__meta_noindex {
            value {
              name
            }
          }
          meta_tags__meta_title {
            value
          }
          meta_tags__meta_type {
            value {
              name
            }
          }
        }
        id
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`