import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterElementDataByLanguage } from '../../common/serviceHelpers'
import { DynamicSitesPath } from '../../common/typings/enums'
import _get from 'lodash/get'

/* Kentico model: Last Articles Section*/
export interface KenticoLastArticlesSection extends KontentItem {
  lastArticles: KenticoLastArticlesSectionElement[]
}

/* Kentico model: Last Articles Section Element*/
export interface KenticoLastArticlesSectionElement extends KontentItem {
  listing_image_or_video: KontentAssetElement
  listing_parallax: KontentAssetElement[],
  title: KontentTextElement
  publication_date: KontentDateElement
  slugs: KontentTextElement
  blog_category: KontentTextElement
}

export class LastArticlesSectionElement {
  id: string
  image: ImageItem
  videoUrl: string
  parallax: ImageItem[]
  title: string
  date: Date
  target: string
  category: string

  constructor(data: KenticoLastArticlesSectionElement) {
    const listingAssetType = _get(data, 'listing_image_or_video.value[0].type', '')
    this.id = _get(data, 'id', '')
    this.image = listingAssetType.includes('image') ? _get(data, 'listing_image_or_video.value[0]') : ''
    this.videoUrl = listingAssetType.includes('video') ? _get(data, 'listing_image_or_video.value[0].url') : ''
    this.parallax = _get(data, 'listing_parallax.value', [])
    this.title = _get(data, 'title.value', '')
    this.date = new Date(_get(data, 'publication_date.value', ''))
    this.target = _get(data, 'slugs.value', '')
    this.category = _get(data, 'blog_category.value[0].name', '')
  }
}

export interface KenticoLastArticlesSectionWithLead extends KontentItem {
  lead: KontentTextElement
  blogs: KenticoLastArticlesSectionElement[]
}

export class LastArticlesSectionWithLead {
  lead: string
  lastArticles: LastArticlesSectionElement[]

  constructor(data: KenticoLastArticlesSectionWithLead) {
    this.lead = _get(data, 'lead.value', '')
    const blogs = new LastArticlesSection(data.blogs, '')
    this.lastArticles = blogs.lastArticles
  }
}

export class LastArticlesSection {
  lastArticles: LastArticlesSectionElement[]

  constructor(data: KenticoLastArticlesSectionElement[], currentArticleId?: string) {
    this.lastArticles = data
      .filter(Boolean)
      .map((element: KenticoLastArticlesSectionElement) => {
        return new LastArticlesSectionElement(element)
      })
    if (currentArticleId) {
      this.sliceThreeRandomArticles(currentArticleId)
    } else {
      this.sortArticlesByDate()
      this.sliceLastThree()
    }
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0
  }

  private sortArticlesByDate(): void {
    this.lastArticles.sort((a: LastArticlesSectionElement, b: LastArticlesSectionElement) => {
      return this.getTime(b.date) - this.getTime(a.date)
    })
  }

  private sliceLastThree(): void {
    this.lastArticles = this.lastArticles.slice(0, 3)
  }

  private sliceThreeRandomArticles(currentArticleId: string) {
    const allButCurrent = this.lastArticles.filter(article => article.id !== currentArticleId)
    this.lastArticles = allButCurrent.sort(() => 0.5 - Math.random()).slice(0, 3)
  }
}

export const lastArticlesSectionFragment = graphql`
  query LastArticlesSectionQuery {
    allKontentItemBlogArticle {
      ...kontent_item_blog_articleConnectionFragment
    }
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_last_articles_section {
                elements {
                  ...kontent_item_last_articles_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const lastArticlesSectionElementsFragment = graphql`
  fragment kontent_item_last_articles_section_elementsFragment on kontent_item_last_articles_section_elements {
    lead {
      value
    }
  }
`

export const useLastArticlesSectionData = (language: string, currentArticleId?: string) => {
  const data = useStaticQuery(lastArticlesSectionFragment)
  return React.useMemo(() => {
    const LastArticlesSectionData: KenticoLastArticlesSectionElement[] =
      filterElementDataByLanguage(data, language, DynamicSitesPath.BLOG).map((lastArticles: any) => lastArticles.elements)
    return new LastArticlesSection(LastArticlesSectionData, currentArticleId)
  }, [data, language, currentArticleId])
}