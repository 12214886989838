import * as React from 'react'
import Person, { PersonProps } from '../Person'
import './style.scss'

const ArticleAuthorSection: React.FC<PersonProps> = (articleAuthor) => {
  const isAuthor = !!articleAuthor.name.trim()
  return <>
    <div className="HorizontalLine mt-mobile-l lg:mt-l mx-mobile-xs lg:mx-desktop-28 mb-mobile-xxs lg:mb-xxs border-b border-separator border-opacity-separator"></div>
    {isAuthor ?
      <section className="ArticleAuthorSection GlobSectPadSpacing grid grid-cols-12 gap-x-m mx-mobile-xs lg:mx-m">
        <Person {...articleAuthor} isArticleAuthor showAllArticles />
      </section>
      : <div className="mb-mobile-xl lg:mb-xl" />
    }
  </>
}

export default ArticleAuthorSection
