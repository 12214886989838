import * as React from 'react'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { AnimationService } from '../../common/AnimationService'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { Layout } from 'gatsby-plugin-image'
import { ImageOptions } from '@kentico/gatsby-kontent-components/dist/image-element/get-gatsby-image-data'
import './style.scss'

export interface ParallaxImageProps {
  backgroundImage: ImageItem
  layer1Image?: ImageItem
  layer2Image?: ImageItem
  layout: Layout
  alt: string
  className: string
  style: React.CSSProperties | undefined
  options: ImageOptions
}

const ParallaxImage: React.FC<ParallaxImageProps> = ({
  backgroundImage,
  layer1Image,
  layer2Image,
  layout,
  alt,
  className,
  style,
  options,
}) => {

  const parallaxBackgroundRef = React.useRef<HTMLDivElement>(null)
  const parallaxLayer1Ref = React.useRef<HTMLDivElement>(null)
  const parallaxLayer2Ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    AnimationService.parallax(parallaxBackgroundRef.current, parallaxLayer1Ref.current, 50)
    AnimationService.parallax(parallaxBackgroundRef.current, parallaxLayer2Ref.current, 20)
  }, [])

  const parallaxImageStyle = React.useMemo(() => ({ height: 'auto' }), [])

  return (
    <>
      <div ref={parallaxBackgroundRef} className="w-full">
        <ImageElement                
          image={backgroundImage}
          layout={layout}
          alt={alt}
          className={className}
          style={style}
          options={options}
        />
      </div>
      {layer1Image && (
        <div ref={parallaxLayer1Ref} className="absolute top-0 left-0 w-full">
          <ImageElement
            image={layer1Image}
            layout={layout}
            className={className}
            style={parallaxImageStyle}
            options={options}
          />
        </div>
      )}
      {layer2Image && (
        <div ref={parallaxLayer2Ref} className="absolute top-0 left-0 w-full">
          <ImageElement
            image={layer2Image}
            layout={layout}
            className={className}
            style={parallaxImageStyle}
            options={options}
          />
        </div>
      )}
    </>
  )
}

export default ParallaxImage
