import { graphql, useStaticQuery } from 'gatsby'
import { filterSeoDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Page Settings & Meta Tags */
interface KenticoSeoData extends KontentItem {
  meta_title: KontentTextElement
  meta_description: KontentTextElement
  meta_image_url: KontentTextElement
  meta_type: KontentTextElement
  meta_keywords: KontentTextElement
  meta_noindex: KontentTextElement
  favicon: KontentAssetElement
}

export class SeoData {
  title?: string
  description?: string
  ogImageUrl?: string
  ogType?: string
  metaKeywords?: string
  noIndex?: string
  canonicalUrl?: string
  favicon?: string

  constructor(data: KenticoSeoData, pathname: string) {
    this.title = _get(data, 'meta_tags__meta_title.value', '')
    this.description = _get(data, 'meta_tags__meta_description.value', '')
    this.ogImageUrl = _get(data, 'meta_tags__meta_image.value[0].url', '')
    this.ogType = _get(data, 'meta_tags__meta_type.value[0].name', '')
    this.metaKeywords = _get(data, 'meta_tags__meta_keywords.value', '')
    this.canonicalUrl = _get(data, 'siteMeta.siteUrl', '') + pathname
    this.favicon = _get(data, 'meta_tags__favicon.value[0].elements.favicon.value[0].url', '')
    this.noIndex = process.env.NODE_ENV === 'development' || process.env.GATSBY_STAGING === 'true'
      ? 'true'
      : _get(data, 'meta_tags__meta_noindex.value[0].name', '')
  }
}

export const seoFragment = graphql`
query SeoQuery {
  allKontentItemPage {
    nodes {
      elements {
        meta_tags__meta_title {
          value
        }
        meta_tags__meta_description {
          value
        }
        meta_tags__meta_image {
          value {
            url
          }
        }
        meta_tags__favicon {
          value {
            ... on kontent_item_favicon {
              elements {
                favicon {
                  value {
                    url
                  }
                }
              }
            }
          }
        }
        meta_tags__meta_type {
          value {
            name
          }
        }
        meta_tags__meta_keywords {
          value
        }
        meta_tags__meta_noindex {
          value {
            name
          }
        }
      }
      system {
        codename
      }
      preferred_language
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}`

export const useSeoData = (language: string, pageCodename: string, pathname: string) => {
  const data = useStaticQuery(seoFragment)
  const seoData: KenticoSeoData = filterSeoDataByLanguage(data, pageCodename, language)
  return new SeoData(seoData, pathname)
}