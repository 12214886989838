import * as React from 'react'
import { RichTextElement, ImageElement } from '@kentico/gatsby-kontent-components'
import Link from '../Link'
import { ArticleContent as ArticleContentClass } from './model'
import { ImageQuality } from '../../common/typings/enums'
import './style.scss'

interface DOMNode {
  name: string
  type: string
  attribs: {
    [name: string]: string;
  }
  children: {
    [name: string]: string;
  }[]
}

const ArticleContent: React.FC<ArticleContentClass> = ({
  value,
  images,
  modular_content,
}) => {
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])

  return (
    <div className="ArticleContent grid grid-cols-12 gap-x-m">
      <RichTextElement
        value={value}
        images={images}
        linkedItems={modular_content}
        resolveImage={image => {
          if (image) {
            return (
              <ImageElement
                image={image}
                alt={image.description || image.name}
                options={imageQualityOptions}
                className="graphic col-span-12 relative -left-m w-screen lg:left-0 lg:w-full"
              />
            )
          } else {
            return <></>
          }
        }}
        resolveLinkedItem={(linkedItem: ArticleContentClass['modular_content'][0]) => {
          switch (linkedItem.system.type) {
          case 'two_column_images': {
            const imageLeftColumn = linkedItem.image_first_column
            const imageRightColumn = linkedItem.image_second_column
            if (!imageLeftColumn || !imageRightColumn) {
              return null
            }
            return (
              <div className="graphic two-images-wrapper col-span-12 grid grid-cols-12 gap-x-m">
                <ImageElement
                  image={imageLeftColumn}
                  alt={imageLeftColumn.description || imageLeftColumn.name}
                  options={imageQualityOptions}
                  className="col-span-12 relative -left-m w-screen lg:col-span-6 lg:left-0 lg:w-full"
                />
                <ImageElement
                  image={imageRightColumn}
                  alt={imageRightColumn.description || imageRightColumn.name}
                  options={imageQualityOptions}
                  className="col-span-12 relative -left-m w-screen lg:col-span-6 lg:left-0 lg:w-full"
                />
              </div>
            )
          }
          case 'video_in_article': {
            return (
              <div className="graphic video-wrapper col-span-12 relative -left-m w-screen lg:left-0 lg:w-full">
                <video autoPlay muted loop playsInline className="w-full">
                  <source
                    src={linkedItem.video_url}
                    type="video/mp4"
                  />
                </video>
              </div>
            )
          }
          default: return null
          }
        }}
        resolveDomNode={(domNode: DOMNode, domToReact: (el: {[name: string]: string }[]) => React.ReactNode) => {
          if (domNode.type === 'tag' && domNode.name === 'a') {
            return (
              <Link
                target={domNode?.attribs?.target || '_self'}
                url={domNode.attribs.href}
              >
                {domToReact([domNode.children[0]]) }
              </Link>
            )
          }
        }}
      />
    </div>
  )
}

export default ArticleContent
