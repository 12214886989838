import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Clients Section */
export interface KenticoClientsSection extends KontentItem {
  header: KontentTextElement
  clients: KontentRichTextElement
  scrolling_speed: KontentNumberElement
}

export class ClientsSection {
  header: string
  richText: string
  scrollingSpeed: number
  
  constructor(data: KenticoClientsSection) {
    this.header = _get(data, 'header.value', '')
    this.richText = _get(data, 'clients.value', '').replace(/ /g, '\u00a0')
    this.scrollingSpeed = _get(data, 'scrolling_speed.value', 1)
  }
}

export const clientsSectionFragment = graphql`
query ClientsSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_clients_scroll_section {
              elements {
                ...kontent_item_clients_scroll_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const clientsSectionElementsFragment = graphql`
  fragment kontent_item_clients_scroll_section_elementsFragment on kontent_item_clients_scroll_section_elements {
    header {
      value
    }
    clients {
      value
    }
    scrolling_speed {
      value
    }
  }
`

export const useClientsSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(clientsSectionFragment)
  const clientsSectionData: KenticoClientsSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new ClientsSection(clientsSectionData)
}