import * as React from 'react'
import Link from '../Link/index'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import './style.scss'

interface BreadcrumbsProps {
  siteCategory: string
  title: string
  category: string
  slug: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ siteCategory, title, category, slug }) => {
  const { t } = useTranslation()
  const breadcrumbs = [
    {
      title: t('common:home'),
      link: '/'
    },
    {
      title: t(`common:${siteCategory}`),
      link: `/${siteCategory}`
    },
    {
      title: title,
      link: `/${siteCategory}/${category}/${slug}`
    }
  ]
  return (
    <section className="Breadcrumbs GlobSectPadSpacing ml-m text-gray-40">
      {
        breadcrumbs.map((element, index) => {
          return (
            <Link key={index} url={element.link}>
              <span className='FontS mr-xs'>{element.title}</span>{(index < breadcrumbs.length - 1) && <span className='FontS mr-xs'>{'-'}</span>}
            </Link>
          )
        })
      }
    </section>
  )
}

export default Breadcrumbs
