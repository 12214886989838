import * as React from 'react'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { AnimationService } from '../../common/AnimationService'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Employee } from './model'
import Link from '../Link'
import { ImageQuality } from '../../common/typings/enums'
import { convertToSlug } from '../../common/serviceHelpers'
import './style.scss'

export interface PersonProps extends Employee {
  style?: React.CSSProperties,
  className?: string
  isArticleAuthor?: boolean
  showAllArticles?: boolean
  animate?: boolean
}

const Person = React.forwardRef<HTMLDivElement, PersonProps>((props: PersonProps, ref) => {
  const {
    name,
    position,
    description,
    photo,
    videoUrl,
    email,
    mobile,
    linkedin,
    style,
    className,
    isArticleAuthor,
    showAllArticles,
    animate = true
  } = props
  const { t } = useTranslation()
  const nameSlug = showAllArticles ? convertToSlug(name) : ''

  const photoRef = React.useRef<HTMLDivElement>(null)
  const descriptionRef = React.useRef<HTMLDivElement>(null)
  const detailsRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (animate) {
      if (!AnimationService.isInView(photoRef.current)) {
        AnimationService.slideUp(photoRef.current)
      }
      if (descriptionRef.current && !AnimationService.isInView(descriptionRef.current)) {
        AnimationService.slideUp(descriptionRef.current)
      }
      if (detailsRef.current && !AnimationService.isInView(detailsRef.current)) {
        AnimationService.slideUp(detailsRef.current)
      }
    }
  }, [isArticleAuthor, animate])
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const imageStyle = React.useMemo(() => ({ width: '100%' }), [])
  const videoStyle = React.useMemo(() => ({ aspectRatio: '1/1' }), [])

  return (
    <div ref={ref} className={`Person ${isArticleAuthor ? 'col-start-1 col-span-12 lg:col-start-4 lg:col-span-6' : 'col-span-12 lg:col-span-3'} ${className || ''}`}>
      <div className={`FontM flex flex-col ${isArticleAuthor ? 'lg:flex-row mb-mobile-xl lg:mb-xl lg:mt-l' : ''}`} style={style}>
        <div className={`Photo overflow-hidden h-auto flex items-center justify-center ${isArticleAuthor ? 'lg:overflow-visible' : ''}`} ref={photoRef}>
          {photo && (
            <ImageElement
              image={photo}
              layout="fullWidth"
              aspectRatio={1}
              alt={photo.description || name}
              className="w-full lg:w-desktop-20 object-cover"
              imgStyle={imageStyle}
              options={imageQualityOptions}
            />
          )}
          {videoUrl && (
            <video
              autoPlay muted loop playsInline
              className="Video overflow-hidden flex object-cover"
              style={videoStyle}
            >
              <source
                src={videoUrl}
                type="video/mp4"
              />
            </video>
          )}
        </div>
        {isArticleAuthor ?
          <div className='Description mt-mobile-m flex flex-col lg:mt-0 lg:ml-m' ref={descriptionRef}>
            <p className='FontL'>{name}</p>
            <p className='FontL text-gray-50 mb-mobile-s lg:mb-s'>{position}</p>
            <p className='FontS'>{description}</p>
            {(linkedin || nameSlug) && (
              <p className='FontS mt-mobile-s lg:mt-s'>
                {linkedin && <Link url={linkedin} title="LinkedIn" className="mr-mobile-s lg:mr-s" />}
                {nameSlug && <Link url={`/blog/author/${nameSlug}`}>{t('blog:allArticlesBy') + name}</Link>}
              </p>
            )}
          </div> :
          <div className='PersonDetails mt-mobile-s flex flex-col lg:mt-s' ref={detailsRef}>
            <p>{name}</p>
            <p>{position}</p>
            <p>{mobile && `${t('common:mobile')}: ${mobile}`}</p>
            <p>{email}</p>
            {linkedin && (
              <p className='FontS mt-mobile-s lg:mt-s lg:grow'>
                <Link url={linkedin} title="LinkedIn" />
              </p>
            )}
          </div>
        }
      </div>
    </div>
  )
})
Person.displayName = 'Person'

export default Person
