import { graphql } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import _get from 'lodash/get'

/* Kentico model: Employee Element */

export interface KenticoEmployeeElement extends KontentItem {
  elements: {
    name: KontentTextElement
    position: KontentTextElement
    description: KontentTextElement
    biography: KontentTextElement
    photo_or_video: KontentAssetElement
    e_mail: KontentTextElement
    mobile: KontentTextElement
    linkedin: KontentTextElement
    facebook: KontentTextElement
  }
}

export class Employee {
  name: string
  position: string
  description: string
  biography: string
  photo: ImageItem
  videoUrl: string
  email: string
  mobile: string
  linkedin: string
  facebook: string

  constructor(rawData: KenticoEmployeeElement) {
    this.name = _get(rawData, 'elements.name.value', '')
    this.position = _get(rawData, 'elements.position.value', '')
    this.description = _get(rawData, 'elements.description.value', '')
    this.biography = _get(rawData, 'element.biography.value', '')
    const employeeAssetType = _get(rawData, 'elements.photo_or_video.value[0].type', '')
    this.photo = employeeAssetType.includes('image') ? _get(rawData, 'elements.photo_or_video.value[0]') : ''
    this.videoUrl = employeeAssetType.includes('video') ? _get(rawData, 'elements.photo_or_video.value[0].url') : ''
    this.email = _get(rawData, 'elements.email.value', '')
    this.mobile = _get(rawData, 'elements.mobile.value', '')
    this.linkedin = _get(rawData, 'elements.linkedin.value', '')
    this.facebook = _get(rawData, 'elements.facebook.value', '')
  }
}
export const employeeFragment = graphql`
  fragment kontent_item_employee_element_elementsFragment on kontent_item_employee_element_elements {
    biography {
      value
    }
    description {
      value
    }
    email {
      value
    }
    facebook {
      value
    }
    linkedin {
      value
    }
    mobile {
      value
    }
    name {
      value
    }
    photo_or_video {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    position {
      value
    }
  }
`
