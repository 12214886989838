import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Image/Video Section */
export interface KenticoImageVideoSection extends KontentItem {
  heading: KontentTextElement
  description: KontentTextElement
  bcg_image_or_video: KontentAssetElement
  button_text: KontentTextElement
  button_target_url: KontentTextElement
  bcg_color: KontentTextElement
  bcg_color_area: KontentTextElement
  embedded_video: KontentTextElement
}

export class ImageVideoSection {
  heading: string
  description: string
  image?: ImageItem
  videoUrl?: string
  buttonText: string
  buttonTargetUrl: string
  backgroundColor: string
  backgroundColorArea: string
  embeddedVideo: string

  constructor(data: KenticoImageVideoSection) {
    const assetType = _get(data, 'bcg_image_or_video.value[0].type', '')
    this.heading = _get(data, 'heading.value', '')
    this.description = _get(data, 'description.value', '')
    this.image = assetType.includes('image') ? _get(data, 'bcg_image_or_video.value[0]') : ''
    this.buttonText = _get(data, 'button_text.value', '')
    this.videoUrl = assetType.includes('video') ? _get(data, 'bcg_image_or_video.value[0].url') : ''
    this.buttonTargetUrl = _get(data, 'button_target_url.value', '')
    this.backgroundColor = _get(data, 'bcg_color.value', '')
    this.backgroundColorArea = _get(data, 'bcg_color_area.value[0].name', '')
    this.embeddedVideo = _get(data, 'embedded_video.value')
  }
}

export const imageVideoSectionFragment = graphql`
  query ImageVideoSectionQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_image_video_banner_section {
                elements {
                  ...kontent_item_image_video_banner_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const imageVideoSectionElementsFragment = graphql`
  fragment kontent_item_image_video_banner_section_elementsFragment on kontent_item_image_video_banner_section_elements {
    bcg_color {
      value
    }
    bcg_color_area {
      value {
        name
      }
    }
    description {
      value
    }
    bcg_image_or_video {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    heading {
      value
    }
    button_text {
      value
    }
    button_target_url {
      value
    }
    embedded_video {
      value
    }
  }
`

export const useImageVideoSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(imageVideoSectionFragment)
  const imageVideoSectionData: KenticoImageVideoSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new ImageVideoSection(imageVideoSectionData)
}
