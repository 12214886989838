import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import { AnimationService } from '../../common/AnimationService'
import Plus from '../../images/plus.svg'
import './style.scss'

export interface PlusButtonProps {
  text: string,
  hoveredText: string,
  isHovered: boolean,
}

const PlusButton: React.FC<PlusButtonProps> = ({ text, hoveredText, isHovered }) => {

  const { isMobile } = React.useContext(IsMobileContext)
  const plusHeight = isMobile ? '6vw' : '1.5vw'
  const buttonsWrapperRef = React.useRef<HTMLDivElement>(null)
  const [animation, setAnimation] = React.useState<GSAPTween | undefined>()

  React.useEffect(() => {
    if (!animation) {
      setAnimation(AnimationService.switchUp(buttonsWrapperRef.current))
    } else {
      if (isHovered) {
        animation.play()
      } else {
        animation.reverse()
      }
    }
  }, [animation, isHovered])

  return (
    <div className="PlusButton_Wrapper h-mobile-s lg:h-s w-full overflow-hidden">
      <div ref={buttonsWrapperRef}>
        <button className='PlusButton h-mobile-s lg:h-s FontS'>
          <span className='PlusButton_Text inline-block'>{text}</span>
        </button>
        <button className='PlusButton h-mobile-s lg:h-s FontS flex flex-row'>
          <Plus className='PlusButton_Icon mr-mobile-xxs lg:mr-xxs' style={{ height: plusHeight }} />
          <span className='PlusButton_Text inline-block'>{hoveredText}</span>
        </button>
      </div>
    </div>
  )
}

export default PlusButton
